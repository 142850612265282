import Header from "../components/header";
import Intro from "../components/intro";
import Roadmap from "../components/roadmap";
import Mint from "../components/mint";
import Team from "../components/team";
import Footer from "../components/footer";

const Home = () => {
  return (
    <>
      <Header />
      <Intro />
      <Roadmap />
      <Mint />
      <Team />
      <Footer />
    </>
  );
};

export default Home;
