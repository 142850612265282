import { Button } from "@chakra-ui/react";

const StyledButton = ({ children, ...rest }) => (
  <Button
    fontSize={"lg"}
    background="gray.900"
    color="white"
    borderRadius="xl"
    fontWeight={700}
    _hover={{ bg: "black" }}
    _active={{ bg: "gray.700" }}
    textTransform="uppercase"
    variant="outline"
    px={16}
    {...rest}
  >
    {children}
  </Button>
);

const MintButton = (props) => {
  return <StyledButton {...props}>Mint</StyledButton>;
};

export default MintButton;
