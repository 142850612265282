import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Flex,
  IconButton,
  Button,
  Stack,
  Collapse,
  Link,
  useBreakpointValue,
  useDisclosure,
  Heading,
  Container,
} from "@chakra-ui/react";
import { useMetaMask } from "metamask-react";
import Web3 from "web3";
import { Link as RRLink } from "react-router-dom";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

import AvaxIcon from "./avax-icon";
import ClaimButton from "./claim-button";
import ABI from "../artifacts/contracts/CosmoCadets.sol/CosmoCadets.json";

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();
  const { account, status } = useMetaMask();
  const [rewards, setRewards] = useState(0);
  const web3 = new Web3(Web3.givenProvider);
  const contract = useMemo(
    () =>
      new web3.eth.Contract(ABI.abi, process.env.REACT_APP_CONTRACT_ADDRESS),
    [web3.eth.Contract]
  );

  useEffect(() => {
    async function callContractData() {
      if (account && contract) {
        const numberOwned = await contract.methods.balanceOf(account).call();

        const secondGenIds = [];

        for (var i = 0; i < numberOwned; i++) {
          const tokenId = await contract.methods
            .tokenOfOwnerByIndex(account, i)
            .call();

          secondGenIds.push(Number(tokenId));
        }

        const rewards = await contract.methods.getRewards(secondGenIds).call();

        setRewards(rewards);
      }
    }

    callContractData();

    const timer = setInterval(() => {
      callContractData();
    }, 5000);

    return () => clearTimeout(timer);
  }, [account, contract]);

  async function claimRewards() {
    if (contract && account) {
      const numberOwned = await contract.methods.balanceOf(account).call();

      const secondGenIds = [];

      for (var i = 0; i < numberOwned; i++) {
        const tokenId = await contract.methods
          .tokenOfOwnerByIndex(account, i)
          .call();

        secondGenIds.push(Number(tokenId));
      }

      await contract.methods.claimRewards(secondGenIds).send({
        from: account,
      });

      const rewards = await contract.methods.getRewards(secondGenIds).call();

      setRewards(rewards);
    }
  }

  return (
    <Box w="full" bg="white" mx="auto">
      <Container maxW="7xl">
        <Flex
          bg={"white"}
          color={"gray.600"}
          minH={"60px"}
          py={{ base: 6 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={"solid"}
          borderColor={"gray.200"}
        >
          <Flex flex={1}>
            <Heading color={"gray.800"} textTransform="uppercase">
              <Link
                _hover={{
                  textDecoration: "none",
                }}
                as={RRLink}
                to="/"
              >
                Cosmo Cadets
              </Link>
            </Heading>
          </Flex>

          <Flex
            ml={{ base: -2 }}
            justify={"flex-end"}
            display={useBreakpointValue({ base: "flex", md: "none" })}
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>

          <Stack
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={2}
            display={useBreakpointValue({ base: "none", md: "flex" })}
          >
            {NAV_ITEMS.map(({ label, href, disabled }) => {
              const isAnchor = href?.includes("#");
              const enabled = !disabled && href;

              if (label === "Claim:") {
                return (
                  <ClaimButton
                    key={label}
                    rewards={rewards}
                    claimRewards={claimRewards}
                    disabled={status !== "connected"}
                  />
                );
              }

              return (
                <Button
                  as={enabled && !isAnchor ? RRLink : "a"}
                  key={label}
                  disabled={disabled}
                  fontSize={"lg"}
                  to={href}
                  href={enabled && isAnchor ? href : undefined}
                  background="gray.900"
                  color="white"
                  borderRadius="xl"
                  fontWeight={700}
                  _hover={{ bg: "black" }}
                  _active={{ bg: "gray.700" }}
                  textTransform="uppercase"
                >
                  {label}
                </Button>
              );
            })}
          </Stack>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <Stack bg={"white"} p={4} display={{ md: "none" }}>
            {NAV_ITEMS.map((navItem) => (
              <MobileNavItem
                key={navItem.label}
                {...navItem}
                rewards={rewards}
                claimRewards={claimRewards}
              />
            ))}
          </Stack>
        </Collapse>
      </Container>
    </Box>
  );
}

const MobileNavItem = ({ label, href, disabled, rewards, claimRewards }) => {
  const isAnchor = href?.includes("#");
  const enabled = !disabled && href;
  const LinkCmp = !isAnchor && enabled ? Link : Box;
  const claimButton = label === "Claim:";

  return (
    <Stack spacing={4}>
      <Flex align="center" py={2} px={1}>
        <Box
          fontWeight={700}
          textTransform="uppercase"
          color={!disabled ? "gray.900" : "gray.500"}
        >
          <LinkCmp
            onClick={() => claimButton && claimRewards()}
            as={!isAnchor && enabled ? RRLink : "a"}
            href={enabled && isAnchor ? href : undefined}
            to={href}
            _hover={{
              textDecoration: "none",
              cursor: !disabled ? "pointer" : "inherit",
            }}
          >
            {label}{" "}
            {claimButton
              ? rewards
                ? (rewards / 1000000000000000000).toFixed(2)
                : "0.00"
              : null}
          </LinkCmp>
        </Box>
        {claimButton && (
          <Box ml={1}>
            <AvaxIcon width={16} height={16} color="black" />
          </Box>
        )}
      </Flex>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "Mint",
    href: "/#mint",
    disabled: false,
  },
  {
    label: "My Collection",
    href: "/my-collection",
    disabled: false,
  },
  {
    label: "Claim:",
    disabled: false,
  },
  {
    label: "The Team",
    href: "/#team",
    disabled: false,
  },
];
