import { memo } from "react";
import { Box, Image, keyframes } from "@chakra-ui/react";

const FloatingCadet = ({ src, ...rest }) => {
  const float = keyframes`
  0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-${Math.floor(Math.random() * (40 - 20 + 1) + 20)}px);
	}
	100% {
		transform: translatey(0px);
	}
`;
  const floatAnimation = `${float} infinite ${Math.floor(
    Math.random() * (6 - 3 + 1) + 3
  )}s ease-in-out`;

  return (
    <Box animation={floatAnimation} {...rest}>
      <Image src={src} />
    </Box>
  );
};

export default memo(FloatingCadet);
