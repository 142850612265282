import { Text, Button } from "@chakra-ui/react";

import AvaxIcon from "./avax-icon";

const ClaimButton = ({ rewards, claimRewards, disabled }) => (
  <Button
    key="claim"
    fontSize={"lg"}
    background="gray.900"
    color="white"
    borderRadius="xl"
    fontWeight={700}
    _hover={{ bg: "black" }}
    _active={{ bg: "gray.700" }}
    textTransform="uppercase"
    rightIcon={<AvaxIcon width={24} height={24} color="white" />}
    onClick={claimRewards}
    disabled={disabled}
  >
    <Text as="span">
      Claim: {rewards ? (rewards / 1000000000000000000).toFixed(3) : "0.000"}
    </Text>
  </Button>
);

export default ClaimButton;
