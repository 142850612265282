import {
  Flex,
  Box,
  Heading,
  Image,
  Container,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

const Team = () => (
  <Box w="full" mx="auto" id="team">
    <Container maxW="6xl">
      <Flex justify="center" my={16}>
        <Heading
          as="h3"
          fontSize="5xl"
          textTransform="uppercase"
          textShadow="2px 2px black"
        >
          The team
        </Heading>
      </Flex>
      <Flex justify="center" my={16}>
        <Stack
          direction={useBreakpointValue({ base: "column", md: "row" })}
          spacing={12}
          align={{ base: "center", md: "flex-end" }}
        >
          {TEAM_MEMBERS.map(({ name, description, image }) => (
            <Box key={name}>
              <Box borderRadius="lg" m={8}>
                <Image src={image} width={300} />
              </Box>
              <Text
                textAlign="center"
                fontSize={"xl"}
                fontWeight={700}
                textTransform="uppercase"
                mb={4}
              >
                {name}
              </Text>
              <Text
                textAlign="center"
                fontSize={"md"}
                fontWeight={700}
                textTransform="uppercase"
              >
                {description}
              </Text>
            </Box>
          ))}
        </Stack>
      </Flex>
    </Container>
  </Box>
);

const TEAM_MEMBERS = [
  {
    name: "Lexi",
    description: "Artist",
    image: "/site-images/lexi.png",
  },
  {
    name: "Pashun",
    description: "Community Manager",
    image: "/site-images/pashun.png",
  },
  {
    name: "Cubbie",
    description: "Community Manager",
    image: "/site-images/cubbies.png",
  },
  {
    name: "otter",
    description: "Developer",
    image: "/site-images/otter.png",
  },
];

export default Team;
