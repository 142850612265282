import {
  Flex,
  Box,
  Heading,
  List,
  ListItem,
  ListIcon,
  Container,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { IoPlanetSharp } from "react-icons/io5";

const Roadmap = () => {
  const roadmapPercentageWidth = useBreakpointValue({
    base: "inherit",
    sm: "50px",
    md: "70px",
  });
  const roadmapFontSize = useBreakpointValue({
    base: "lg",
    md: "2xl",
  });
  const roadmapTextAlign = useBreakpointValue({
    base: "center",
    sm: "left",
  });

  return (
    <Box w="full" mx="auto">
      <Container maxW="6xl" my={16}>
        <Flex justify="center" mb={8}>
          <Heading
            as="h3"
            fontSize="5xl"
            textTransform="uppercase"
            textShadow="2px 2px black"
          >
            Roadmap
          </Heading>
        </Flex>
        <Box>
          <List spacing={4}>
            {ROADMAP_ITEMS.map(({ percentage, reward }) => (
              <ListItem
                key={percentage}
                fontSize={roadmapFontSize}
                fontWeight={700}
                textTransform="uppercase"
                textAlign={roadmapTextAlign}
              >
                <Text
                  display="inline-block"
                  textAlign="right"
                  width={roadmapPercentageWidth}
                >
                  {percentage}%
                </Text>
                <ListIcon ml={2} mr={4} as={IoPlanetSharp} color="white" />
                {reward}
              </ListItem>
            ))}
          </List>
        </Box>
      </Container>
    </Box>
  );
};

const ROADMAP_ITEMS = [
  {
    percentage: 0,
    reward: "10% reflections for early minters",
  },
  {
    percentage: 25,
    reward: "25 avax giveaway (5 winners. 5 avax ea)",
  },
  {
    percentage: 50,
    reward: "25 avax giveaway (5 winners. 5 avax ea)",
  },
  {
    percentage: 75,
    reward: "25 avax giveaway (5 winners. 5 avax ea)",
  },
  {
    percentage: 100,
    reward: "Free pfp mint for genesis holders (1:1)",
  },
];

export default Roadmap;
