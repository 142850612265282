import { useMetaMask } from "metamask-react";
import { Button } from "@chakra-ui/react";

const StyledButton = ({ children, ...rest }) => (
  <Button
    fontSize={"lg"}
    background="gray.900"
    color="white"
    borderRadius="xl"
    fontWeight={700}
    _hover={{ bg: "black" }}
    _active={{ bg: "gray.700" }}
    textTransform="uppercase"
    variant="outline"
    px={16}
    {...rest}
  >
    {children}
  </Button>
);

const ConnectButton = () => {
  const { status, connect } = useMetaMask();

  // const switchAndConnect = async () => {
  //   try {
  //     await window.ethereum.request({
  //       method: "wallet_switchEthereumChain",
  //       params: [{ chainId: "0xA86A" }],
  //     });
  //   } catch (e) {
  //     if (e.code === 4902) {
  //       try {
  //         await window.ethereum.request({
  //           method: "wallet_addEthereumChain",
  //           params: [
  //             {
  //               name: "Avalanche Mainnet",
  //               chain: "AVAX",
  //               rpc: ["https://api.avax.network/ext/bc/C/rpc"],
  //               faucets: [
  //                 "https://free-online-app.com/faucet-for-eth-evm-chains/",
  //               ],
  //               nativeCurrency: {
  //                 name: "Avalanche",
  //                 symbol: "AVAX",
  //                 decimals: 18,
  //               },
  //               infoURL: "https://www.avax.network/",
  //               shortName: "Avalanche",
  //               chainId: "0xa86a",
  //               networkId: 43114,
  //               slip44: 9000,
  //               explorers: [
  //                 {
  //                   name: "snowtrace",
  //                   url: "https://snowtrace.io",
  //                   standard: "EIP3091",
  //                 },
  //               ],
  //             },
  //           ],
  //         });
  //       } catch (addError) {
  //         console.error(addError);
  //       }
  //     }
  //     // console.error(e)
  //   } finally {
  //     await connect();
  //   }
  // };

  if (status === "initializing")
    return <StyledButton isLoading loadingText="Syncing" />;

  if (status === "unavailable")
    return <StyledButton disabled>Unavailable</StyledButton>;

  if (status === "notConnected")
    return <StyledButton onClick={connect}>Connect</StyledButton>;

  if (status === "connecting")
    return (
      <StyledButton onClick={connect} isLoading loadingText="Connecting" />
    );

  return null;
};

export default ConnectButton;
