import Header from "../components/header";
import Collection from "../components/collection";
import Footer from "../components/footer";

const MyCollection = () => {
  return (
    <>
      <Header />
      <Collection />
      <Footer />
    </>
  );
};

export default MyCollection;
