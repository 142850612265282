import { useState, useEffect } from "react";
import Web3 from "web3";
import { useMetaMask } from "metamask-react";
import {
  Flex,
  Heading,
  Box,
  Container,
  SimpleGrid,
  Center,
  Spinner,
} from "@chakra-ui/react";

import ABI from "../artifacts/contracts/CosmoCadets.sol/CosmoCadets.json";
import Cadet from "./cadet";
import ConnectButton from "./connect-button";

const Collection = () => {
  const { account } = useMetaMask();

  const [cadetsOwned, setCadetsOwned] = useState([]);
  const [loading, setLoading] = useState();

  useEffect(() => {
    async function callContractData() {
      const web3 = new Web3(Web3.givenProvider);
      const contract = new web3.eth.Contract(
        ABI.abi,
        process.env.REACT_APP_CONTRACT_ADDRESS
      );

      setLoading(true);

      if (account) {
        const amountOwned = await contract.methods.balanceOf(account).call();

        const ownedIds = [];

        for (var i = 0; i < amountOwned; i++) {
          const tokenId = await contract.methods
            .tokenOfOwnerByIndex(account, i)
            .call();

          ownedIds.push(tokenId);
        }

        if (ownedIds.length) {
          const metadata = Promise.all(
            ownedIds.map(async (id) => {
              try {
                const req = await fetch(
                  `${process.env.REACT_APP_TOKEN_BASE_API_URL}${id}.json`,
                  {
                    headers: {
                      Accept: "application/json",
                    },
                  }
                );
                const res = await req.json();
                return res;
              } catch (err) {
                console.error(err);
              }
            })
          );

          setCadetsOwned(await metadata);
        }
      }

      setLoading(false);
    }

    callContractData();
  }, [account]);

  return (
    <Box>
      <Box w="full" mx="auto" py={16} background="semi-transparent">
        <Container maxW="6xl" position="relative">
          <Flex justify="center">
            <Heading
              as="h2"
              fontSize={{ base: "5xl", md: "7xl" }}
              textTransform="uppercase"
              textAlign="center"
              zIndex={2}
              textShadow="2px 2px black"
            >
              My Collection
            </Heading>
          </Flex>
        </Container>
      </Box>
      <Box w="full" mx="auto" background="white">
        <Container maxW="6xl" align="center" py={6}>
          {!account && (
            <Box pt={12}>
              <ConnectButton />
            </Box>
          )}
          {account && loading && (
            <Center pt={12}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="black"
                size="xl"
              />
            </Center>
          )}
          {account && !loading && !cadetsOwned.length && (
            <Heading as="h3" color="black" pt={12}>
              No Cadets owned
            </Heading>
          )}
          {account && !loading && cadetsOwned.length && (
            <SimpleGrid
              columns={[1, 2, 3, 4]}
              spacing="1"
              pt={8}
              alignItems="stretch"
            >
              {cadetsOwned.map((cadet) => (
                <Cadet {...cadet} key={cadet.tokenId} />
              ))}
            </SimpleGrid>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Collection;
