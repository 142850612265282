import { useState, useEffect, useMemo } from "react";
import Web3 from "web3";
import {
  Flex,
  Box,
  Heading,
  Container,
  HStack,
  Button,
  Input,
  useNumberInput,
  Text,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useMetaMask } from "metamask-react";
import { useNavigate } from "react-router-dom";

import FloatingCadet from "./floating-cadet";
import MintButton from "./mint-button";
import ConnectButton from "./connect-button";
import ABI from "../artifacts/contracts/CosmoCadets.sol/CosmoCadets.json";

// const shorter = (str) =>
//   str?.length > 8 ? str.slice(0, 4) + "...." + str.slice(-4) : str;

const StyledButton = ({ children, ...rest }) => (
  <Button
    fontSize={"lg"}
    background="gray.900"
    color="white"
    borderRadius="xl"
    fontWeight={700}
    _hover={{ bg: "black" }}
    _active={{ bg: "gray.700" }}
    textTransform="uppercase"
    variant="outline"
    px={16}
    {...rest}
  >
    {children}
  </Button>
);

function MintAmountInput({ input, inc, dec }) {
  return (
    <HStack maxW="320px">
      <Button
        {...dec}
        fontSize={"lg"}
        background="gray.900"
        color="white"
        borderRadius="xl"
        fontWeight={700}
        _hover={{ bg: "black" }}
        _active={{ bg: "gray.700" }}
        textTransform="uppercase"
        variant="outline"
      >
        -
      </Button>
      <Input
        {...input}
        width="100px"
        textAlign="center"
        fontSize={"lg"}
        background="gray.900"
        color="white"
        borderRadius="xl"
        fontWeight={700}
      />
      <Button
        {...inc}
        fontSize={"lg"}
        background="gray.900"
        color="white"
        borderRadius="xl"
        fontWeight={700}
        _hover={{ bg: "black" }}
        _active={{ bg: "gray.700" }}
        textTransform="uppercase"
        variant="outline"
      >
        +
      </Button>
    </HStack>
  );
}

const MintSection = ({ status, disabled, isLoading, mintFn }) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
      max: 20,
      precision: 0,
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <>
      <MintAmountInput input={input} inc={inc} dec={dec} />
      <>
        {status === "connected" ? (
          <MintButton
            disabled={disabled}
            onClick={() => mintFn(input.value)}
            isLoading={isLoading}
            loadingText="Minting"
          />
        ) : (
          <ConnectButton />
        )}
      </>
    </>
  );
};

const Mint = () => {
  const navigate = useNavigate();
  const { status, account, chainId } = useMetaMask();
  const [saleStarted, setSaleStarted] = useState(false);
  const [amountMinted, setAmountMinted] = useState("?");
  const [mintError, setMintError] = useState();
  const [isMinting, setMinting] = useState(false);
  const web3 = new Web3(Web3.givenProvider);
  const contract = useMemo(
    () =>
      new web3.eth.Contract(ABI.abi, process.env.REACT_APP_CONTRACT_ADDRESS),
    [web3.eth.Contract]
  );
  const connected = status === "connected";

  useEffect(() => {
    const liveUpdate = async () => {
      if (connected) {
        const paused = await contract.methods.paused().call();
        setSaleStarted(!paused);

        const minted = await contract.methods.totalSupply().call();
        setAmountMinted(minted);
      }
    };

    async function callContractData() {
      const paused = await contract.methods.paused().call();
      setSaleStarted(!paused);

      const minted = await contract.methods.totalSupply().call();
      setAmountMinted(minted);
    }

    callContractData();

    const timer = setInterval(() => {
      liveUpdate();
    }, 5000);

    return () => clearTimeout(timer);
  }, [chainId, account, contract.methods, connected]);

  async function mint(howMany) {
    setMintError();
    setMinting(true);
    if (contract) {
      try {
        const mintFee = await contract.methods.mintFee().call();
        await contract.methods.mint(howMany).send({
          from: account,
          value: Number(mintFee) * howMany,
        });
        setMinting(false);
        const minted = await contract.methods.totalSupply().call();
        setAmountMinted(minted);
      } catch (error) {
        console.error(error);
        if (error.code !== 4001) {
          setMintError(error);
        }
        setMinting(false);
      }
    }
  }

  return (
    <Box w="full" mx="auto" background="semi-transparent" id="mint">
      <Container maxW="6xl" my={24}>
        <Flex justify="center">
          <Heading
            as="h3"
            fontSize="5xl"
            textTransform="uppercase"
            align={"center"}
            textShadow="2px 2px black"
          >
            Find your Cosmo Cadet
          </Heading>
        </Flex>
        <Flex justify="center" mb={12} mt={16}>
          <Heading
            as="h4"
            fontSize="4xl"
            textTransform="uppercase"
            align={"center"}
            textShadow="2px 2px black"
          >
            Public Mint
          </Heading>
        </Flex>
        <Box align="center">
          <FloatingCadet src="/site-images/cadet-1.png" />
          <Text
            mt={12}
            fontSize="2xl"
            textTransform="uppercase"
            align={"center"}
          >
            1.25 AVAX per Cadet
          </Text>
          <Stack
            my={12}
            direction={useBreakpointValue({ base: "column", md: "row" })}
            justify="center"
            align="center"
            spacing={8}
          >
            <MintSection
              disabled={!saleStarted}
              mintFn={mint}
              isLoading={isMinting}
              status={status}
            />
            <Box fontSize={"xl"} fontWeight={700} textTransform="uppercase">
              <Text>
                {amountMinted.toLocaleString()}/{(1000).toLocaleString()} Minted
              </Text>
            </Box>
          </Stack>
          {mintError && (
            <Text color="red" mt={4} mb={8}>
              An error occurred when attempting to mint: {mintError.message}
            </Text>
          )}

          <Box align="center" mt={4} mb={8}>
            <StyledButton onClick={() => navigate("/my-collection")}>
              View your Cosmo Cadets
            </StyledButton>
          </Box>
          {/* <Text>{shorter(account)}</Text> */}
        </Box>
      </Container>
    </Box>
  );
};

export default Mint;
