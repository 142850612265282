import { Flex, Heading, Box, Container } from "@chakra-ui/react";

const Intro = () => (
  <Box w="full" mx="auto" height="100vh" py={48} background="semi-transparent">
    <Container maxW="6xl" position="relative">
      <Flex justify="center">
        <Heading
          as="h2"
          fontSize={{ base: "5xl", md: "7xl" }}
          textTransform="uppercase"
          textAlign="center"
          zIndex={2}
          textShadow="2px 2px black"
        >
          Page not found
        </Heading>
      </Flex>
    </Container>
  </Box>
);

export default Intro;
