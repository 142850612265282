import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider, extendTheme, Flex } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import { MetaMaskProvider } from "metamask-react";

import Home from "./pages/home";
import MyCollection from "./pages/my-collection";
import NotFoundPage from "./pages/404";

function getLibrary(provider) {
  return new Web3Provider(provider);
}

const theme = extendTheme({
  fonts: {
    heading: "AstroSpace, sans-serif",
  },
  colors: {
    base: "black",
    "semi-transparent": "rgba(0,0,0,0.5)",
  },
  shadows: {
    dark: "0 10px 15px -3px rgba(0, 0, 0, 1), 0 4px 6px -2px rgba(0, 0, 0, 0.9)",
  },
  styles: {
    global: {
      body: {
        color: "white",
      },
    },
  },
});

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'AstroSpace';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/AstroSpace.woff') format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      .pulse {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #58d958;
        box-shadow: 0 0 0 rgba(0,169,0, 0.4);
        animation: pulse 2.5s infinite;
      }

      @-webkit-keyframes pulse {
        0% {
          -webkit-box-shadow: 0 0 0 0 rgba(0,169,0, 0.4);
        }
        70% {
            -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
        }
        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        }
      }
      @keyframes pulse {
        0% {
          -moz-box-shadow: 0 0 0 0 rgba(0,169,0, 0.4);
          box-shadow: 0 0 0 0 rgba(0,169,0, 0.4);
        }
        70% {
            -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
            box-shadow: 0 0 0 10px rgba(204,169,44, 0);
        }
        100% {
            -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
            box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        }
      }
      `}
  />
);

function App() {
  return (
    <Flex direction="column" align="stretch" minHeight="100%">
      <ChakraProvider theme={theme}>
        <Fonts />
        <Web3ReactProvider getLibrary={getLibrary}>
          <MetaMaskProvider>
            <Router>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/my-collection" element={<MyCollection />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Router>
          </MetaMaskProvider>
        </Web3ReactProvider>
      </ChakraProvider>
    </Flex>
  );
}

export default App;
