import { Flex, Heading, Box, Container } from "@chakra-ui/react";

import FloatingCadet from "./floating-cadet";

const Intro = () => (
  <Box w="full" mx="auto" py={48} background="semi-transparent">
    <Container maxW="6xl" position="relative">
      <FloatingCadet
        src="/site-images/cadet-2.png"
        pos="absolute"
        mt={-100}
        ml={0}
        width={192}
      />
      <FloatingCadet
        src="/site-images/cadet-3.png"
        pos="absolute"
        ml={300}
        mt={150}
        width={73}
      />
      <FloatingCadet
        src="/site-images/cadet-4.png"
        pos="absolute"
        ml={600}
        mt={0}
        width={92}
      />
      <FloatingCadet
        src="/site-images/cadet-5.png"
        pos="absolute"
        ml={800}
        mt={0}
        width={177}
      />
      <Flex justify="center">
        <Heading
          as="h2"
          fontSize={{ base: "5xl", md: "7xl" }}
          textTransform="uppercase"
          textAlign="center"
          zIndex={2}
          textShadow="2px 2px black"
        >
          Discover your Cosmo Cadet
        </Heading>
      </Flex>
    </Container>
  </Box>
);

export default Intro;
