import {
  Center,
  Flex,
  Heading,
  Image,
  Box,
  Stack,
  Text,
} from "@chakra-ui/react";

export default function socialProfileWithImageHorizontal({
  image,
  tokenId,
  attributes,
}) {
  return (
    <Center p={1} height="100%">
      <Stack borderWidth="1px" boxShadow={"lg"}>
        <Heading fontSize={"2xl"} color="black" p={4} pt={8}>
          Cadet #{tokenId}
        </Heading>
        <Flex flex={1}>
          <Image boxShadow="inner" width="100%" height="100%" src={image} />
        </Flex>
        <Stack flex={1} flexDirection="column" p={4}>
          <Box size="md" color="black">
            {attributes.map((attr) => (
              <Text key={attr.trait_type} align="left" fontSize="md">
                {attr.trait_type}: <Text as="span">{attr.value}</Text>
              </Text>
            ))}
          </Box>
        </Stack>
      </Stack>
    </Center>
  );
}
