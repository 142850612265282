import { Box, Container, Stack, Text, Link, Flex } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { FaTwitter, FaDiscord } from "react-icons/fa";

export default function SmallWithSocial() {
  return (
    <Box w="full" mx="auto" bg="white" color="black">
      <Container maxW="6xl" my={16}>
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          pb={0}
          direction={{ base: "column", md: "row" }}
          align="center"
          justify="center"
        >
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={{ base: "6", md: "12" }}
          >
            <Link
              fontSize={{ base: "lg", lg: "2xl" }}
              fontWeight={700}
              href="https://discord.gg/pNr7RWtDj3"
              isExternal
            >
              <Flex justify="center" align="center" gap={10}>
                <FaDiscord />
                <Text textTransform="uppercase" ml={2}>
                  Discord
                </Text>
                <ExternalLinkIcon mx={2} />
              </Flex>
            </Link>
            <Link
              fontSize={{ base: "lg", lg: "2xl" }}
              fontWeight={700}
              href="https://twitter.com/CosmoCadets"
              isExternal
            >
              <Flex justify="center" align="center" gap={10}>
                <FaTwitter />
                <Text textTransform="uppercase" ml={2}>
                  Twitter
                </Text>
                <ExternalLinkIcon mx={2} />
              </Flex>
            </Link>
          </Stack>
        </Container>
        <Text align="center" p={2} mt={4}>
          <Link
            isExternal
            href={process.env.REACT_APP_TOKEN_BASE_API_URL.split("/token/")[0]}
          >
            <span className="pulse"></span>
          </Link>
          <Link
            ml={4}
            isExternal
            href={`https://${
              process.env.REACT_APP_RPC_URL ===
              "https://api.avax-test.network/ext/bc/C/rpc"
                ? "testnet."
                : ""
            }snowtrace.io/address/${process.env.REACT_APP_CONTRACT_ADDRESS}`}
          >
            {process.env.REACT_APP_RPC_URL ===
            "https://api.avax-test.network/ext/bc/C/rpc"
              ? "Fuji Testnet"
              : "Mainnet"}
          </Link>
        </Text>
      </Container>
    </Box>
  );
}
